<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" variant="primary" @click="adminNotificationAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-7 col-lg-8 mb-2">
                <b-form-input class="form-control"
                  @input="searchadminNotificationWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col class="col-12 col-sm-4 col-md-5 col-lg-4 pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getadminNotificationDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="adminNotificationObjList && adminNotificationObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="adminNotificationObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                <template v-slot:cell(module_name)="data">
                    {{data.item.module_name}}
                    <div class="status" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') ">
                      <b-badge v-if="data.item.deleted === 0" class="pointer float-right " variant="success mr-1 mb-1" size="sm">
                        Published
                      </b-badge>
                      <b-badge v-if="data.item.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm">
                        Unpublished
                      </b-badge>
                      <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm">
                        Deleted
                      </b-badge>
                      <b-button variant="light mr-1 mb-1" title="Unpublish"  :disabled="data.item.deleted === 2" @click="notifPublishUnpublish(data.item)">
                      <i class="fa fa-toggle-off m-0 font-size-20" style="color:red"></i>
                      </b-button>
                      <b-button variant="light mr-1 mb-1" title="Publish" :disabled="data.item.deleted === 0" @click="notifPublishUnpublish(data.item)">
                        <i class="fa fa-toggle-on m-0 font-size-20" style="color:green"></i>
                      </b-button>
                    </div>
                </template>
                <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="adminNotificationEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showAdminNotificationDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col v-if="adminNotificationObjList && adminNotificationObjList.length > 0" sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelAdminNotificationAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <AdminNotificationAdd :propOpenedInModal="true" @emitCloseAdminNotificationAddModal="closeAdminNotificationAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="adminNotificationAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeAdminNotificationAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelAdminNotificationEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <AdminNotificationEdit :propOpenedInModal="true" :propAdminNotificationObj="adminNotificationEditObj" @emitCloseAdminNotificationEditModal="closeAdminNotificationEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="adminNotificationEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeAdminNotificationEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelAdminNotificationDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelAdminNotificationDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="adminNotificationDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { AdminNotifications } from "../../../FackApi/api/AdminNotification.js"
import AdminNotificationAdd from "./AdminNotificationAdd"
import AdminNotificationEdit from "./AdminNotificationEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "AdminNotificationList",
  components: {
    AdminNotificationAdd,
    AdminNotificationEdit
  },
  data () {
    return {
      apiName: "admin_notification_list",
      cvCardTitle: "Notifications",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Notification",
      cvAddModalHeader: "Add Notification",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Notification",
      showModelAdminNotificationAdd: false,
      showModelAdminNotificationEdit: false,
      showModelAdminNotificationDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [],
      adminNotificationObjList: null,
      adminNotificationEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    socialvue.index()
    await this.getDateFilter()
    await this.adminNotificationList()
    this.initilize()
  },
  methods: {
  /**
   * initialize
   */
    initilize () {
      switch (this.userData.user_role) {
        case "USERROLE11111": // Admin
        case "USERROLE11118": // Counsellor
          this.columns = [
            { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

            { label: "Notification Title", key: "notification_title", class: "text-left align-text-top", sortable: true },
            { label: "Notification Desc", key: "notification_desc", class: "text-left align-text-top", sortable: true },
            { label: "Participant Id", key: "participant_id", class: "text-left align-text-top", sortable: true },
            { label: "Module Name", key: "module_name", class: "text-left align-text-top", sortable: true },
            { label: "Module Id", key: "module_id", class: "text-left align-text-top", sortable: true },
            { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
            { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
          ]
          break
        case "USERROLE11112":
        case "USERROLE11113":
        case "USERROLE11114":
        case "USERROLE11115": // Univ Admin
        case "USERROLE11116": // Orgs
        case "USERROLE11117": // School
          this.columns = [
            { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
            { label: "Notification Title", key: "notification_title", class: "text-left align-text-top", sortable: true },
            { label: "Notification Desc", key: "notification_desc", class: "text-left align-text-top", sortable: true }
          ]
          break
        default:
          break
      }
    },
    /**
     * Publish/UnPublish noti
     */
    async notifPublishUnpublish (data) {
      let payload = {
        notification_id: data.notification_id,
        deleted: null
      }

      if (data.deleted === 0) {
        // noti is published then unpublish
        payload.deleted = 2
      }
      else if (data.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      let notifResp = await AdminNotifications.adminNotificationPublishUnpublish(this, payload)
      if (notifResp.resp_status === true) {
        data.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, notifResp)
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchadminNotificationWithParams
     */
    async searchadminNotificationWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.adminNotificationList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.adminNotificationList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    async getadminNotificationDateWise () {
      this.adminNotificationList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * adminNotificationList
     */
    async adminNotificationList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let adminNotificationListResp = await AdminNotifications.adminNotificationList(this, this.whereFilter)
        if (adminNotificationListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.adminNotificationObjList = [ ...adminNotificationListResp.resp_data.data ]
          }
          else {
            this.adminNotificationObjList = [ ...this.adminNotificationObjList, ...adminNotificationListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.adminNotificationObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.adminNotificationObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = adminNotificationListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at adminNotificationList() and Exception:", err)
      }
    },
    /**
     * adminNotificationAdd
     */
    adminNotificationAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../admin_notification_add")
        }
        else {
          this.showModelAdminNotificationAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at adminNotificationAdd() and Exception:", err.message)
      }
    },
    /**
     * adminNotificationEdit
     */
    adminNotificationEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../admin_notification_edit/" + this.adminNotificationEditObj.notification_id)
        }
        else {
          this.adminNotificationEditObj = item
          this.showModelAdminNotificationEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at adminNotificationEdit() and Exception:", err.message)
      }
    },
    /**
     * showAdminNotificationDeleteDialog
     */
    showAdminNotificationDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelAdminNotificationDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showAdminNotificationDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * adminNotificationDelete
     */
    async adminNotificationDelete () {
      try {
        let adminNotificationDelResp = await AdminNotifications.adminNotificationDelete(this, this.delObj.notification_id)
        await ApiResponse.responseMessageDisplay(this, adminNotificationDelResp)

        if (adminNotificationDelResp && !adminNotificationDelResp) {
          this.showModelAdminNotificationDelete = false
          return false
        }

        let index = this.adminNotificationObjList.indexOf(this.delObj)
        this.adminNotificationObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelAdminNotificationDelete = false
      }
      catch (err) {
        console.error("Exception occurred at adminNotificationDelete() and Exception:", err.message)
      }
    },
    /**
     * closeAdminNotificationAddModal
     */
    closeAdminNotificationAddModal (adminNotificationAddData) {
      try {
        if (adminNotificationAddData) {
          if (this.adminNotificationObjList && this.adminNotificationObjList.length >= 1) {
            let adminNotificationObjLength = this.adminNotificationObjList.length
            let lastId = this.adminNotificationObjList[adminNotificationObjLength - 1]["id"]
            adminNotificationAddData.id = lastId + 1
          }
          else {
            this.adminNotificationObjList = []
            adminNotificationAddData.id = 11111
          }

          adminNotificationAddData.created_on = moment()
          this.adminNotificationObjList.unshift(adminNotificationAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelAdminNotificationAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeadminNotificationAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeAdminNotificationEditModal
     */
    closeAdminNotificationEditModal () {
      try {
        this.showModelAdminNotificationEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeAdminNotificationEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
