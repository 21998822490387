/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">

                <div class="col-md-12 mb-3">
                  <label for="validationparticipant_id">
                    {{cvParticipantIdLabel}}</label
                  >
                  <input
                    v-model="vmAdminNotificationFormData.participant_id"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_name">
                    {{cvModuleNameLabel}}</label
                  >
                  <input
                    v-model="vmAdminNotificationFormData.module_name"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_id">
                    {{cvModuleIdLabel}}</label
                  >
                  <input
                    v-model="vmAdminNotificationFormData.module_id"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationnotification_title">
                    {{cvNotificationTitleLabel}}</label
                  >
                  <input
                    v-model="vmAdminNotificationFormData.notification_title"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationnotification_desc">
                    {{cvNotificationDescLabel}}</label
                  >
                  <input
                    v-model="vmAdminNotificationFormData.notification_desc"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="adminNotificationAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { AdminNotifications } from "../../../FackApi/api/AdminNotification"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "AdminNotificationAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "admin_notification_add",
      cvCardTitle: "Add Notification",
      cvSubmitBtn: "Add",
      cvParticipantIdLabel: "Participant id",
      cvModuleNameLabel: "Module name",
      cvModuleIdLabel: "Module id",
      cvNotificationTitleLabel: "Notification title",
      cvNotificationDescLabel: "Notification desc",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "AdminNotification Added",
      vmAdminNotificationFormData: Object.assign({}, this.initFormData()),
      vmAdminNotificationDesc: null,
      vmAdminNotificationType: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "participant_id": "",
        "module_name": "",
        "module_id": "",
        "notification_title": "",
        "notification_desc": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmAdminNotificationFormData) {
          if (!this.vmAdminNotificationFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * adminNotificationAdd
     */
    async adminNotificationAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let adminNotificationAddResp = await AdminNotifications.adminNotificationAdd(this, this.vmAdminNotificationFormData)
        await ApiResponse.responseMessageDisplay(this, adminNotificationAddResp)
        if (adminNotificationAddResp && !adminNotificationAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseAdminNotificationAddModal", adminNotificationAddResp.resp_data)
        }
        this.vmAdminNotificationFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at adminNotificationAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
